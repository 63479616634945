/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pt--60" style={{"paddingBottom":53}} name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Ušetřete čas i peníze!\n</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":800}} content={"Provádění prací svépomocí je sice finančně úsporné, ale neobejde se bez pořádných svalů nebo kvalitní techniky. Pokud tedy plánujete ušetřit peníze a nechcete se zbytečně dřít ani vyhazovat peníze za profesionální výkopové firmy, můžete si bagr jednoduše pronajmout.\n\n"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Z důvodu vysokého počtu poptávek prosíme o vyplnění poptávkového formuláře níže, obratem se ozveme."}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-120514x css-4k5rg4 pb--40 pt--40" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/95a76501b0cc4e77b5c11615a1185855_s=3000x_.jpeg);
    }
  
background-position: 6% 49%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim7 --anim-s2 pb--16 pl--10 pr--10 pt--16" style={{"maxWidth":600,"backgroundColor":"var(--color-custom-2)","marginBottom":65}} anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--30" content={"<span style=\"color: var(--white);\">Rychlá poptávky</span><br>"}>
              </Title>

              <Text className="text-box" style={{"marginTop":12,"marginBottom":26}} content={"<span style=\"color: var(--black);\">Vyplněním všech polí zrychlíte a zpřesníte cenovou nabídku.</span>"}>
              </Text>

              <ContactForm className="--shape2 --style1 fs--16" style={{"maxWidth":500}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"xs9h7vwjb7ps"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"axle4gr727k3"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"tn4fyg9k0q2g"},{"id":"dkoayyeklu1s","type":"select","name":"Půjčuji jako:","placeholder":"Vyberte","options":[{"id":"a6yvywogf8xj","value":"fyzická osoba"},{"id":"sfdw7u207q75","value":"osvč"},{"id":"1s6aw1m8c1pt","value":"firma"}]},{"id":"vbhahd89h3tv","type":"date","name":"Datum od","placeholder":"Datum od"},{"id":"u90rla3lqm67","type":"time","name":"Čas od","placeholder":"Čas od"},{"id":"d59k7nvfj0o6","type":"date","name":"Datum do","placeholder":"Datum do"},{"id":"7pudhkojavro","type":"time","name":"Čas do","placeholder":"Čas do"},{"id":"ney19pap1ghj","type":"select","name":"Požaduji kalkulaci dopravy","placeholder":"Vyberte","options":[{"id":"86ke0pzcl2ev","value":"ANO"},{"id":"ky5jjwbfki7e","value":"NE (můžete si vyzvednout sami)"}]},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"g39wmt3qgtqo"},{"id":"6x3jl4ltpqma","type":"plaintext","content":"Odesláním formuláře souhlasíte se zpracováním osobních údajů."},{"name":"Odeslat","type":"submit","id":"3oqg2svtliyw"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim7 --anim-s2 pb--16 pl--10 pr--10 pt--16" style={{"maxWidth":600,"backgroundColor":"var(--color-custom-2)"}} anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--30" content={"<span style=\"color: var(--white);\">Kontakt - Provozovatel</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box" style={{"marginTop":22}} content={"Petr Kubík"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":9}} content={"<span style=\"color: var(--black);\">petr541@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--2" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: var(--white);\"><a href=\"https://minibagry-ostrava.cz/\">MINIBAGRY-OSTRAVA.CZ\n</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"petr541@gmail.com"}>
              </Text>

              <Text className="text-box" content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}